import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Checkbox from "@material-ui/core/Checkbox";
import SearchIcon from "@material-ui/icons/Search";
import ExportIcon from "@material-ui/icons/Publish";
// import ImportIcon from "@material-ui/icons/GetApp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import * as actionTypes from "../../../store/action";
import Spinner from "../../Spinner/Spinner";
import "./Table.css";
import "../Head/Head.css";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const minDate = new Date();
minDate.setFullYear(minDate.getFullYear() - 1);

let searchTimer;
function Table(props) {
  const [dateValue, setDateValue] = useState(new Date());
  const [calendarOpen, setCalendarOpen] = useState(false);

  const searchInput = useRef();

  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [columnSelectorOpen, setColumnSelectorOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [visibleColumnsCount, setVisibleColumnsCount] = useState(0);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [visibleData, setVisibleData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(props.tableDataLoaded);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontSize: "16px",
        fontWeight: "bold",
        backgroundColor: "#EEEEEE",
        borderRight: "1px solid #ccc",
        color: "#000",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        borderRight: "1px solid #ddd",
        width: "150px",
      },
    },
  };

  const populateVisibleColumns = (totalColumns) => {
    const result = totalColumns.filter((item) => item.visible);
    setVisibleColumnsCount(result.length);
    setVisibleColumns(result);
  };

  const columnChangedHandler = (event, index) => {
    const myColumns = [...columns];
    myColumns[index].visible = event.target.checked;
    setColumns(myColumns);
    populateVisibleColumns(myColumns);
  };

  const debounceSearchData = (search) => {
    clearTimeout(searchTimer);

    searchTimer = setTimeout(() => {
      searchData(search);
    }, 600);
  };

  const searchData = (search) => {
    if (!search) {
      setVisibleData(data);
      return;
    }
    const value = search.trim();

    searchInput.current.placeholder = "Searching";

    const result = data.filter((item) => {
      if (
        item.user_name.search(new RegExp(value, "i")) >= 0 ||
        item.employee_id === value
      )
        return true;
      else return false;
    });

    setVisibleData(result);
    searchInput.current.placeholder = "Search";
  };

  const exportTransaction = () => {
    if (props.token) {
      const month = dateValue.getMonth();
      const year = dateValue.getFullYear();

      fetch(
        `${process.env.REACT_APP_SERVER}/ws/v1/user-disbursement/export-admin-disbursement-history/`,
        {
          method: "POST",
          headers: {
            authorization: `Token ${props.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            month: month + 1,
            year: year,
          }),
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `NinjaSalary_employee_transaction_data.xlsx`
          );

          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);
        })
        .catch((err) => {
          console.log(
            "Can't connect to server for Downloading sample file",
            err
          );
        });
    }
  };

  useEffect(() => {
    const columns = [
      {
        name: "Id",
        selector: "employee_id",
        visible: true,
        checked: true,
        wrap: true,
        width: "100px",
      },
      {
        name: "Name",
        selector: "user_name",
        visible: true,
        checked: true,
        wrap: true,
      },
      {
        name: "Widthdrawn amount",
        selector: "withdrawn_amount_in_paisa",
        visible: true,
        wrap: true,
      },

      {
        name: "Commission amount",
        selector: "commission_amount_in_paisa",
        visible: true,
        wrap: true,
      },
      {
        name: "Tax amount",
        selector: "tax_amount_in_paisa",
        visible: true,
        wrap: true,
      },
      {
        name: "Net amount",
        selector: "net_amount_in_paisa",
        visible: true,
        wrap: true,
      },
    ];
    setColumns(columns);
    if (props.tableData && props.tableData.length > 0) {
      const temp = props.tableData.map((item) => {
        item.commission_amount_in_paisa = `₹ ${
          Number.parseFloat(item.commission_amount_in_paisa) / 100
        }`;
        item.net_amount_in_paisa = `₹ ${
          Number.parseFloat(item.net_amount_in_paisa) / 100
        }`;
        item.withdrawn_amount_in_paisa = `₹ ${
          Number.parseFloat(item.withdrawn_amount_in_paisa) / 100
        }`;
        item.tax_amount_in_paisa = `₹ ${
          Number.parseFloat(item.tax_amount_in_paisa) / 100
        }`;
        return item;
      });
      setData(temp);
      setVisibleData(temp);
    } else if (props.tableData && props.tableData.length === 0) {
      setData([]);
      setVisibleData([]);
    }
    populateVisibleColumns(columns);
  }, [props.tableData]);
  useEffect(() => {
    setDataLoaded(props.tableDataLoaded);
  }, [props.tableDataLoaded]);
  return (
    <div>
      <div className="transaction_head_container">
        <div
          className={`transaction_head_backdrop ${
            calendarOpen ? "transaction_head_backdrop_active" : ""
          }`}
          onClick={() => setCalendarOpen(false)}
        />
        <div className="transaction_head">
          <div className="transaction_head_left">
            <h1>Transaction History</h1>
          </div>
          <div
            className="transaction_head_right"
            onClick={() => setCalendarOpen(!calendarOpen)}
            style={{ boxShadow: "1px 0 12px rgba(0 0 0 / 20%)" }}
          >
            <div style={{ textAlign: "center", cursor: "pointer" }}>
              <h3>{monthNames[dateValue.getMonth()]}</h3>
              <p>{dateValue.getFullYear()}</p>
            </div>
            <div
              className={`transaction_head_calendar ${
                calendarOpen ? "transaction_head_calendar_active" : ""
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <Calendar
                defaultView="year"
                maxDate={new Date()}
                minDate={minDate}
                onClickMonth={(value) => {
                  const month = value.getMonth();
                  const year = value.getFullYear();
                  props.refreshAll(month + 1, year);
                  setDateValue(value);
                  setCalendarOpen(false);
                }}
                value={dateValue}
                view="year"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="transaction_table">
        <div
          className={`transaction_backdrop ${
            columnSelectorOpen ? "transaction_backdrop_active" : ""
          }`}
          onClick={() => setColumnSelectorOpen(false)}
        />
        <div className="transaction_table_topbar">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div
              className="transaction_table_topbar_item custom-column"
              style={{ cursor: "pointer" }}
              onClick={() => setColumnSelectorOpen(!columnSelectorOpen)}
            >
              <p>
                Columns{" "}
                <span
                  style={{
                    fontWeight: "bolder",
                    fontSize: "1rem",
                    paddingLeft: "0 2px",
                  }}
                >
                  {visibleColumnsCount}
                </span>
              </p>
              <ArrowDropDownIcon />

              <div
                className={`custom-column-box ${
                  columnSelectorOpen ? "custom-column-box_active" : ""
                }`}
                onClick={(e) => e.stopPropagation()}
              >
                {columns.map((item, i) => (
                  <div key={i}>
                    {item.checked ? (
                      <Checkbox checked disabled style={{ color: "gray" }} />
                    ) : (
                      <Checkbox
                        checked={item.visible}
                        style={{ color: "var(--primary-color)" }}
                        onChange={(event) => columnChangedHandler(event, i)}
                      />
                    )}
                    <p>{item.name}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="transaction_table_topbar_item">
              <p>Total money to Pay </p>
              <span>₹ {props.totalMoney || "_"}</span>
            </div>
          </div>
        </div>
        <hr />
        <div className="transaction_table_navbar">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="select_count">{selectedRowCount}</div>
            <div className="search">
              <input
                type="text"
                onChange={(e) => {
                  debounceSearchData(e.target.value);
                }}
                onKeyUp={(e) =>
                  e.keyCode === 13 ? searchData(e.target.value) : ""
                }
                ref={searchInput}
                placeholder="Search"
              />
              <SearchIcon onClick={searchData} style={{ cursor: "pointer" }} />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            {/* <div className="table_navbar_item">
            <ImportIcon
              style={{
                backgroundColor: "green",
                color: "#fff",
                borderRadius: "50%",
                fontSize: "2rem",
                padding: "5px",
              }}
            />
            <p>Import</p>
          </div> */}
            <div className="table_navbar_item" onClick={exportTransaction}>
              <ExportIcon
                style={{
                  backgroundColor: "red",
                  color: "#fff",
                  borderRadius: "50%",
                  fontSize: "2rem",
                  padding: "5px",
                }}
              />
              <p>Export</p>
            </div>
          </div>
        </div>

        {dataLoaded ? (
          <DataTable
            columns={visibleColumns}
            customStyles={customStyles}
            style={{
              border: "1px solid lightgray",
            }}
            data={visibleData}
            highlightOnHover
            striped
            responsive
            noHeader
            selectableRows
            selectableRowsComponent={Checkbox}
            selectableRowsComponentProps={{
              style: { color: "var(--primary-color)" },
            }}
            selectableRowsHighlight
            onSelectedRowsChange={(obj) => {
              // setSelectedRows(obj.selectedRows);
              setSelectedRowCount(obj.selectedCount);
            }}
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 15, 20, 30, 60]}
          />
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutAction: () => dispatch({ type: actionTypes.LOGOUT }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
